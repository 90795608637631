
import React from "react";
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Footer } from "./Footer";
import GabrielAndreolliImage from './assets/gabriel-andreolli.png'
import { ArrowRight } from "react-bootstrap-icons";
import { MDBContainer } from "mdb-react-ui-kit";
import Navbar from './Navbar';
import './styles/talento.css'


function GabrielAndreolli () {
  const videos = [
    {
      id: 1,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "XP Investimentos, Fator XP",
      client: "",
      agency: "In-house",
      description: "Uma imersão com os top 100 assessores de todo o Brasil no HQ da XP em São Paulo e o prazer desafiador de trabalhar com não-atores. Pessoas reais, histórias reais. ",
      paddingTop: "32.5vh",
      url: "https://player.vimeo.com/video/906042993?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 2,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "right",
      title: "Mitsubishi, MIT x MIT",
      client: "",
      agency: "Tech and Soul",
      description: "A tecnologia e a sofisticação unidas para apresentar o novo Mitsubishi Eclipse Cross.  Com um LED interativo que destaca os features exclusivos do novo carro da icônica marca.",
      paddingTop: "23.5vh",
      url: "https://player.vimeo.com/video/878647340?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 3,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "Truno + OWLT + Winebathory, I'll Go Around",
      client: "",
      agency: "",
      description: "Videoclipe de performance experimental que conecta a estética vintage com o visual litorâneo em uma contraposição contemporânea e atemporal.",
      paddingTop: "2vh",
      url: "https://player.vimeo.com/video/882522603?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 4,
      tags: [{ name: "", color: 'tag-blue' }/* , {name: "GLASS / CANNES", color: 'tag-yellow'} */],
      direction: "right",
      title: "Gab Ferreira, Karma",
      client: "",
      agency: "",
      description: "O videoclipe apresenta uma performance que transmite a intensidade da artista independente Gab Ferreira. ",
      paddingTop: "35vh",
      url: "https://player.vimeo.com/video/882519991?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait0&controls=true"
    }
  ]
  videos.sectionHeightCustom = 'section section-video-gabriel'
  return (
    <>
      <Navbar />
      <Container fluid className='section section-director-gabriel'>
        <Row>
          <Col className='title-director'>
            <span >
              Talentos /
            </span>
          </Col>
        </Row>
        <Row className='name-director'>
          <Col xs={{ span: 6 }} sm={{ span: 6 }} md={12} lg={12} xl={12}>
            <span>Gabriel Andreolli</span>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 5 }} className='description-director desk'>
            <span >
              Gabriel usa o seu trabalho para mostrar a energia latente da juventude. Apaixonado por música e pela cena underground, ele iniciou sua carreira no cinema em Buenos Aires - Argentina, quando produziu o seu primeiro videoclipe. Sua sensibilidade e dinamismo mostram a sua estética moderna que ascende à superfície.
            </span>
          </Col>
          <Col lg={{ offset: 1 }} className="image-director">
            <Image src={GabrielAndreolliImage} fluid />
          </Col>
        </Row>
        <Row>
          <Col className='description-director mobile'>
            <span >
              Gabriel usa o seu trabalho para mostrar a energia latente da juventude. Apaixonado por música e pela cena underground, ele iniciou sua carreira no cinema em Buenos Aires - Argentina, quando produziu o seu primeiro videoclipe. Sua sensibilidade e dinamismo mostram a sua estética moderna que ascende à superfície.
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="movie-director" >
            <span className="movie-director-1">Filmes de</span>
            <br/>
            <span className="movie-director-2">Gabriel Andreolli</span>
          </Col>
        </Row>
        {videos.map( ( video, index ) => (
          <>
            {video.direction == "left" ?
              <>
                <Row key={index}  className='video-top'>
                  <Col xs={12} sm={12} lg={{ span: 3 }} xl={{ span: 3 }} className="video-title video-space">
                    {video.tags.map( ( tag, index ) => (
                      <>
                        {
                          tag.award != undefined &&
                          <span className="label award-video">
                            {tag.award}
                          </span>
                        }
                      </>
                    ) )}
                    <h2 className="desk">{video.tags[0].name}</h2>
                    <span>{video.title}</span>
                    <p className="video-title-agency">{video.agency}</p>
                    <MDBContainer className="video-mdb mobile">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                    <p className="video-description">
                      {video.description}
                    </p>
                  </Col>
                  <Col lg={{ span: 8, offset: 1 }} xl={{ span: 8, offset: 1 }} className="desk video-space">
                    <MDBContainer className="video-mdb">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                  </Col>
                </Row>
              </>
              :
              <Row key={index}  className='video-top'>
                <Col md={4} lg={8} xl={8} className="desk video-space">
                  <MDBContainer className="video-mdb">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                </Col>
                <Col xs={12} sm={12} md={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }} className="video-title video-space">
                  {video.tags.map( ( tag, index ) => (
                    <>
                      {
                        tag.award != undefined &&
                        <span className="label award-video">
                          {tag.award}
                        </span>
                      }
                    </>
                  ) )}
                  <h2 className="desk">{video.tags[0].name}</h2>
                  <span>{video.title}</span>
                  <p className="video-title-agency">{video.agency}</p>
                  <MDBContainer className="video-mdb mobile">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                  <p className="video-description">
                    {video.description}
                  </p>
                </Col>
              </Row>
            }
          </>
        ) )}
        <Row>
          <Col xs={{ span: 6, offset: 6 }} sm={{ span: 6, offset: 6 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} xl={{ span: 8, offset: 4 }} className="video-arrow">
            <a className="mobile" target='blank' href=" https://vimeo.com/showcase/10663135">
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={32} />
              </p>
            </a>
            <a className="desk" target='blank' href=" https://vimeo.com/showcase/10663135">
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={42} />
              </p>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default GabrielAndreolli;