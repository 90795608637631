import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './styles/Navbar.css';
import Logo from './assets/logo_left.png';
import MenuTogle from './assets/menu.png';
import './styles/global.css';

function OffcanvasExample () {
  const [isMenuOpen, setIsMenuOpen] = useState( false );
  const [isReady, setIsReady] = useState( false );
  const [navbarClass, setNavbarClass] = useState( 'bg-transparent' );

  useEffect( () => {
    setIsReady( true );

    const handleScroll = () => {
      if ( window.scrollY > 50 ) {
        setNavbarClass( 'bg-white' );
      } else {
        setNavbarClass( 'bg-transparent' );
      }
    };

    window.addEventListener( 'scroll', handleScroll );

    return () => {
      window.removeEventListener( 'scroll', handleScroll );
    };
  }, [] );

  const handleToggle = () => {
    if ( isMenuOpen ) {
      setIsMenuOpen( false );
      document.body.classList.remove( 'menu-open' );
    } else {
      setTimeout( () => {
        setIsMenuOpen( true );
        document.body.classList.add( 'menu-open' );
      }, 500 ); // Adiciona um atraso de 500ms antes de abrir o menu
    }
  };

  const handleMouseEnter = () => {
    document.body.classList.add( 'custom-cursor' );
  };

  const handleMouseLeave = () => {
    document.body.classList.remove( 'custom-cursor' );
  };

  return (
    <>
      <Navbar key={false} expand={false} className={`${navbarClass} border-bottom border-dark`} fixed="top">
        <Container fluid className="d-flex justify-content-between align-items-center">
          <div className="col-4 d-flex justify-content-start">
            <Navbar.Brand href="/">
              <img
                src={Logo}
                className="logo-menu"
                alt="Logo"
              />
            </Navbar.Brand>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <Navbar.Text className="d-none d-sm-block d-xs-block text-center">
              <span className="navbar-title-text">FILMES, EXPERIÊNCIAS & INOVAÇÃO</span>
            </Navbar.Text>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} onClick={handleToggle}>
              <img
                src={MenuTogle}
                className="d-inline-block align-top button-menu-icon"
                alt="Menu Button"
              />
            </Navbar.Toggle>
          </div>
        </Container>
        <Navbar.Offcanvas
          placement="end"
          className={`offcanvas-custom ${isReady ? ( isMenuOpen ? 'open' : '' ) : ''} d-flex justify-content-between`}
          show={isMenuOpen}
          onHide={handleToggle}
        >
          <Offcanvas.Header closeButton className='section-menu'>
            <div className="col-4 d-flex">
              <Navbar.Brand href="/">
                <img
                  src={Logo}
                  className="logo-menu-open col-4 d-flex"
                  alt="Logo"
                />
              </Navbar.Brand>
            </div>
            <div className="col-4 d-flex justify-content-center">
              <Offcanvas.Title className='title-menu col-4 d-flex justify-content-center'>
                <span className="navbar-title-text">FILMES, EXPERIÊNCIAS & INOVAÇÃO</span>
              </Offcanvas.Title>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className='section-option-menu' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Nav className="" >
              <Nav.Link className='movies-menu' href="/filmes">FILMES</Nav.Link>
              <Nav.Link href="/experiencias">EXPERIÊNCIAS</Nav.Link>
              <Nav.Link href="/talentos">TALENTOS</Nav.Link>
              <Nav.Link href="/sobre-nos">SOBRE NÓS</Nav.Link>
              <Nav.Link href="/contato">CONTATO</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}

export default OffcanvasExample;
