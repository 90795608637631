
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Footer } from "./Footer";
import Navbar from './Navbar';
import Elipse from './assets/elipse.png'
import './styles/contact.css'

const Contact = () => {

  return (
    <>
      <Navbar />
      <Container fluid className='section section-contact'>
        <Row>
          <Col className='title-page'>
            <span>
              Contato
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={6} xl={6} className='address-contact'>
            <span>
              Rua Girassol, 1033 <br /> Vila Madalena <br /> São Paulo, SP
            </span>
          </Col>
          <Col xs={{ span: 11 }} sm={12} lg={6} xl={6} className='group-contact'>
            <p  className='email-contact'>
              atendimento
              <br className='mobile' />
              @notsoimpossible.com.br
            </p>
          </Col>
          <Col xs={12} sm={12}   className='phone-contact'>
            
          </Col>
        </Row>
        <Row>
          <Col>
            <img className='elipse elipse-1' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-2' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-3' src={Elipse} />
          </Col>
        </Row>
        <Row >
          <Col xs={12} className='maps-contact'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1828.7429943661848!2d-46.694067411492966!3d-23.550982987838875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce57bf12594f47%3A0x182f6b73235e1dd3!2sR.%20Girassol%2C%201033%20-%20Pinheiros%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2005433-002!5e0!3m2!1spt-BR!2sbr!4v1714850377393!5m2!1spt-BR!2sbr"
              width="100%"
              height="750"
              style={{ border: "0", padding: "1.5vw", }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </Col>
        </Row>
      </Container >
      <Footer />
    </>
  );
}

export default Contact;