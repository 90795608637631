
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Footer } from "./Footer";
import Navbar from './Navbar';
import Elipse from './assets/elipse-blue.png'
import { ArrowRight } from "react-bootstrap-icons";
import { MDBContainer } from "mdb-react-ui-kit";

import "./styles/filmes.css"

const Movies = () => {
  const videos = [
    {
      id: 1,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "McDonald's, ASMR McCrispy",
      client: "",
      agency: "Galeria",
      description: "Uma experiência ASMR que desperta o desejo. O som nunca foi tão delicioso e a gente pode provar!",
      paddingTop: "22vh",
      url: "https://player.vimeo.com/video/731207621?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 2,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "right",
      title: "XP Investimentos, Fator XP",
      client: "",
      agency: "In-house",
      description: "Uma imersão desafiadora com os top 100 assessores de todo o Brasil no HQ da XP em São Paulo. ",
      paddingTop: "33.5vh",
      url: "https://player.vimeo.com/video/906042993?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 3,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "Mitsubishi, MIT x MIT",
      client: "",
      agency: "Tech and Soul",
      description: "A tecnologia e a sofisticação unidas para apresentar o novo Mitsubishi Eclipse Cross.",
      paddingTop: "21vh",
      url: "https://player.vimeo.com/video/878647340?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 4,
      tags: [{ name: "", color: 'tag-blue', award: "GLASS / CANNES 2018" }/* , {name: "GLASS / CANNES", color: 'tag-yellow'} */],
      direction: "right",
      title: "Schweppes, Dress For Respect",
      client: "",
      agency: "Ogilvy",
      description: "Um vestido com sensores ultra-sensíveis que registram cada toque recebido para documentar o assédio que as mulheres enfrentam em festas.",
      paddingTop: "28vh",
      url: "https://player.vimeo.com/video/272241191?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    },
    {
      id: 5,
      tags: [{ name: "", color: 'tag-blue' }],
      direction: "left",
      title: "Chevrolet, Camaro 50th",
      client: "",
      agency: "WMcCann / Washington Olivetto",
      description: "Um Camaro saltando entre dois caminhões em movimento com os stunts de Velozes e Furiosos.",
      paddingTop: "35vh",
      url: "https://player.vimeo.com/video/228257881?h=aae3da8ca2&autoplay=0&loop=1&muted=1&color=ff9933&title=0&byline=0&portrait=0&controls=true"
    }
  ]
  videos.sectionHeightCustom = 'section-video-movie'
  return (
    <>
      <Navbar />
      <Container fluid className='section section-movie'>
        <Row>
          <Col className='title-page'>
            <span>Filmes</span>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6} className='subtitle-movie desk '>
            <p>
              A NSI tira grandes ideias do papel e as torna possíveis.
            </p>
          </Col>
          <Col lg={6} xl={6} className='subtitle-movie-right'>
            <p>
              Unimos a arte e a tecnologia para criar filmes e experiências que <span className='link-movie'>ultrapassam os formatos tradicionais</span>, onde a narrativa é o que nos conecta com o novo.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className='section second-section-movie' >
        <Row>
          <Col>
            <img className='elipse elipse-1' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-2' src={Elipse} />
          </Col>
          <Col>
            <img className='elipse elipse-3' src={Elipse} />
          </Col>
        </Row>
        <Row>
          <Col lg={{ offset: 2 }} className='second-title-movie title-one'>
            <span>Nossos</span>
          </Col>
        </Row>
        <Row>
          <Col className='second-title-movie title-two'>
            <span>Filmes </span>
          </Col>
        </Row>
        {videos.map( ( video, index ) => (
          <>
            {video.direction == "left" ?
              <>
                <Row key={index}  className='video-top'>
                  <Col xs={12} sm={12} lg={{ span: 3 }} xl={{ span: 3 }} className="video-title video-space">
                    {video.tags.map( ( tag, index ) => (
                      <>
                        {
                          tag.award != undefined &&
                          <span className="label award-video">
                            {tag.award}
                          </span>
                        }
                      </>
                    ) )}
                    <h2 className="desk">{video.tags[0].name}</h2>
                    <span>{video.title}</span>
                    <p className="video-title-agency">{video.agency}</p>
                    <MDBContainer className="video-mdb mobile">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                    <p className="video-description">
                      {video.description}
                    </p>
                  </Col>
                  <Col lg={{ span: 8, offset: 1 }} xl={{ span: 8, offset: 1 }} className="desk video-space">
                    <MDBContainer className="video-mdb">
                      <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                        <iframe
                          src={video.url}
                          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                          frameborder="0"
                          webkitallowfullscreen
                          mozallowfullscreen
                          allow="autoplay"
                          muted="muted"
                        >
                        </iframe>
                      </div><script src="https://player.vimeo.com/api/player.js"></script>
                    </MDBContainer>
                  </Col>
                </Row>
              </>
              :
              <Row key={index}  className='video-top'>
                <Col md={4} lg={8} xl={8} className="desk video-space">
                  <MDBContainer className="video-mdb">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                </Col>
                <Col xs={12} sm={12} md={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }} className="video-title video-space">
                  {video.tags.map( ( tag, index ) => (
                    <>
                      {
                        tag.award != undefined &&
                        <span className="label award-video">
                          {tag.award}
                        </span>
                      }
                    </>
                  ) )}
                  <h2 className="desk">{video.tags[0].name}</h2>
                  <span>{video.title}</span>
                  <p className="video-title-agency">{video.agency}</p>
                  <MDBContainer className="video-mdb mobile">
                    <div style={{ padding: '60.25% 0 0 0', position: 'relative' }}>
                      <iframe
                        src={video.url}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100% ' }}
                        frameborder="0"
                        webkitallowfullscreen
                        mozallowfullscreen
                        allow="autoplay"
                        muted="muted"
                      >
                      </iframe>
                    </div><script src="https://player.vimeo.com/api/player.js"></script>
                  </MDBContainer>
                  <p className="video-description">
                    {video.description}
                  </p>
                </Col>
              </Row>
            }
          </>
        ) )}
        <Row>
          <Col xs={{ span: 6, offset: 6 }} sm={{ span: 6, offset: 6 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} xl={{ span: 8, offset: 4 }} className="video-arrow">
            <a className='mobile' target='blank' href=' https://vimeo.com/showcase/8799750'>
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={32} />
              </p>
            </a>
            <a className='desk' target='blank' href=' https://vimeo.com/showcase/8799750'>
              <p className='see-vimeo'>
                Veja mais<br /> no Vimeo
                <ArrowRight className="arrow-right" size={42} />
              </p>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Movies;