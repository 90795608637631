import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { ArrowRight } from "react-bootstrap-icons";
import Elipse from './assets/elipse.png'
import './styles/footer.css'
import LogoFooter from './assets/logo_footer.png'

export const Footer = () => {

  return (
    <>
      <Container fluid className='section section-end'>
        <Row className='elipses-end'>
          <Col>
            <img className='elipses-end-1' src={Elipse} alt="elipse-end-1" />
          </Col>
          <Col>
            <img className='elipses-end-2' src={Elipse} alt="elipse-2" />
          </Col>
          <Col>
            <img className='elipses-end-3' src={Elipse} alt="elipse-3" />
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col md={{ span: 9 }} className='title-end'>
            <span style={{ fontStyle: "italic" }}>O impossível é nosso combustível. Uma forma de pensar e produzir.</span>
          </Col>
        </Row>
        <Row>
          <Col xs={1} lg={4}></Col>
          <Col className='subtitle-impossible-end'>
            <a className='link-footer desk' href='/contato'>Venha fazer o
              <br />
              <span>impossível com a gente </span>
              <ArrowRight size={42} />
            </a>
            <a className='link-footer mobile' href='/contato'>Venha fazer o
              <br />
              <span>impossível com a gente </span>
              <ArrowRight size={32} />
            </a>
          </Col>
        </Row>
        <div className="line-footer-home" />
        <Row className='footer'>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className='logo-footer-home'>
            <a href='/'> <Image className="" src={LogoFooter} fluid /></a>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className='menu-footer-home'>
            <a className='link-footer' href='/filmes'>FILMES </a>
            <a className='link-footer' href='/experiencias'>- EXPERIÊNCIAS </a>
            <a className='link-footer' href='/sobre-nos'>- SOBRE NÓS </a>
            <a className='link-footer' href='/talentos'>- TALENTOS </a>
            <a className='link-footer' href='/contato'>- CONTATO </a>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className='social-footer-home'>
            <a target='blank' className='link-footer' href='https://www.instagram.com/notsoimpossiblemedia'>INSTAGRAM </a>
            <a target="blank" className='link-footer' href='https://vimeo.com/notsoimpossible'>- VIMEO </a>
          </Col>
        </Row>
      </Container>
    </>
  )
}
